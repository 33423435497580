import { Outlet } from "react-router-dom";
import { Navigation } from "./Navigation";

export const PrivateLayout: React.FC = () => {
  return (
    <div className="private-layout">
      <Navigation />
      <div className="private-layout__content">
        <div className="private-layout__content__outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
