import { LoyaltyHistoryEntry, UserLoyaltyInformation } from "@9010/shared";
import { TFunction } from "react-i18next";
import i18n from "../../i18n";
import {
  FairMarketingInfo,
  FairMarketingInfoAmounts,
} from "./FairMarketing.types";
import dayjs, { Dayjs } from "dayjs";

/**
 * Extract the balance, pending, withdrawed and total amounts of the fair marketing program in the {@link UserLoyaltyInformation}.
 *
 * @param loyaltyInformation the loyalty info to use
 * @returns a {@link FairMarketingInfoAmounts} object which holds the requested information
 * @tested
 */
export const extractFairMarketingAmountsFromLoyaltyInformation = (
  loyaltyInformation: UserLoyaltyInformation
): FairMarketingInfoAmounts => {
  const now: Dayjs = dayjs();
  const history: LoyaltyHistoryEntry[] =
    loyaltyInformation.affiliatePointsHistory ?? [];

  const balanceAmount: number = [
    ...history.map(({ blockedUntil, payedOut, points }) =>
      (!blockedUntil || (blockedUntil && now.isAfter(blockedUntil.toDate()))) &&
      !payedOut
        ? points
        : 0
    ),
    0,
  ].reduce((pre, cur) => pre + cur);

  const pendingAmount: number = [
    ...history.map(({ blockedUntil, payedOut, points }) =>
      blockedUntil && now.isBefore(blockedUntil.toDate()) && !payedOut
        ? points
        : 0
    ),
    0,
  ].reduce((pre, cur) => pre + cur);

  const withdrawedAmount: number = [
    ...history.map(({ payedOut, points }) => (!!payedOut ? points : 0)),
    0,
  ].reduce((pre, cur) => pre + cur);

  const totalAmount: number = balanceAmount + pendingAmount + withdrawedAmount;

  return {
    balanceAmount,
    pendingAmount,
    withdrawedAmount,
    totalAmount,
  };
};

/**
 * Generate the fair marketing info table with the provided fair marketing info.
 * This tables show the points of each user and the total.
 *
 * @param data a {@link FairMarketingInfo} array which is received from the swr hook.
 * @returns the table as a react node
 * @tested
 */
export const generateFairMarketingTable = (data: FairMarketingInfo[]) => {
  const t: TFunction = i18n.t;
  const isEmpty: boolean = data.length === 0;

  const sum: FairMarketingInfo = isEmpty
    ? {
        balanceAmount: 0,
        pendingAmount: 0,
        withdrawedAmount: 0,
        totalAmount: 0,
        userEmail: "",
        userId: "",
        userName: "",
      }
    : data.reduce((pre, cur) => ({
        balanceAmount: cur.balanceAmount + pre.balanceAmount,
        pendingAmount: cur.pendingAmount + pre.pendingAmount,
        withdrawedAmount: cur.withdrawedAmount + pre.withdrawedAmount,
        totalAmount: cur.totalAmount + pre.totalAmount,
        userEmail: "",
        userId: "",
        userName: "",
      }));

  return (
    <div className="fair-marketing-page__table">
      <div className="fair-marketing-page__table__column">
        <div>{t("pages.fairmarketing.table.headingName")}</div>
        <div>{t("pages.fairmarketing.table.headingAmount")}</div>
      </div>
      <hr />
      {isEmpty ? (
        <div className="fair-marketing-page__table__column">
          {t("pages.fairmarketing.noEntries")}
        </div>
      ) : (
        data.map((entry) => (
          <div
            className="fair-marketing-page__table__column"
            key={entry.userEmail}
          >
            <div>
              {entry.userEmail} ({entry.userName})
            </div>
            <div>
              <b>{entry.balanceAmount.toFixed(2)}</b> (
              {entry.pendingAmount.toFixed(2)}) /{" "}
              {entry.withdrawedAmount.toFixed(2)} /{" "}
              {entry.totalAmount.toFixed(2)}
            </div>
          </div>
        ))
      )}
      <hr />
      <div className="fair-marketing-page__table__column">
        <div>{t("pages.fairmarketing.table.total")}</div>
        <div>
          <b>{sum.balanceAmount.toFixed(2)}</b> ({sum.pendingAmount.toFixed(2)})
          / {sum.withdrawedAmount.toFixed(2)} / {sum.totalAmount.toFixed(2)}
        </div>
      </div>
    </div>
  );
};
