import React, { FormEvent, useState } from "react";
import {
  ButtonComponent,
  InputComponent,
  PopupComponent,
} from "react-component-library";
import { useTranslation } from "react-i18next";
import { logInWithEmailAndPassword } from "../utils/FirebaseUtils";

const allowedEmailRegex: RegExp = /@(9010\.com|devduck\.de)$/g;

export const LoginForm: React.FC<{}> = () => {
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { t } = useTranslation();
  const [showLoginError, toggleLoginErrorPopup] = useState<boolean>(false);

  /**
   * Helper to handle the user login event
   *
   * @param evt The actual form event
   */
  const handleSubmit = (evt: FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();
    if (!allowedEmailRegex.test(mail)) {
      toggleLoginErrorPopup(true);
      return;
    }
    logInWithEmailAndPassword(mail, password).then((success) => {
      if (success) return;
      toggleLoginErrorPopup(true);
    });
  };

  return (
    <div className="login-form">
      <form onSubmit={handleSubmit}>
        <InputComponent
          type="email"
          value={mail}
          label={t("general.mail")}
          onChange={setMail}
          required
        />
        <InputComponent
          type="password"
          value={password}
          label={t("general.password")}
          onChange={setPassword}
          required
        />
        <ButtonComponent type="submit" value={t("general.login")} />
      </form>
      <PopupComponent
        show={showLoginError}
        onClose={() => toggleLoginErrorPopup(false)}
        onClick={() => toggleLoginErrorPopup(false)}
        buttonColor="#ce0b65"
        content={
          <div className="login-page__error-popup__content">
            <p>{t("pages.login.invalid")}</p>
          </div>
        }
        buttonText={t("pages.login.invalidButton")}
        hideLine
      />
    </div>
  );
};
