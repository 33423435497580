import axios from "axios";

/**
 * This axios instance is preconfigured for the
 * medbed backend service
 */
export const medBedAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_SERVICE_MEDBED,
  timeout: 1500000,
});
