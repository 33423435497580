import { PrivateUserInformation } from "@9010/shared";
import {
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  User as FirebaseUser,
  UserCredential,
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../firebase";

const googleProvider = new GoogleAuthProvider();

/**
 * Helper method to sign in using the social login Google provides.
 * In case it is the first login, a new User entry on the database
 * will get created
 */
export const signInWithGoogle = async (): Promise<void> => {
  const userDatabase: string | undefined = process.env.REACT_APP_USER_DATABASE;
  if (!userDatabase) {
    console.error("User database param not set!");
    return;
  }
  try {
    const newEntry: UserCredential = await signInWithPopup(
      auth,
      googleProvider
    );
    const user: FirebaseUser = newEntry.user;
    const userRef = await addDoc(collection(db, userDatabase), {
      uid: user.uid,
      name: user.displayName,
      type: undefined!, //TODO set correct type
    });
    const userPrivateRef = collection(
      db,
      userDatabase,
      userRef.id,
      "privateInformation"
    );
    await addDoc(userPrivateRef, {
      authProvider: "google",
      email: user.email,
    } as PrivateUserInformation);
  } catch (err: any) {
    console.error("Error during user creation with google loing", err);
  }
};

/**
 * Helper method to login an user with the help of the given mail and password
 *
 * @param email The user entered mail
 * @param password The user entered password
 */
export const logInWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return true;
  } catch (err: any) {
    console.error("Error during login!", err);
    return false;
  }
};

/**
 * Helper to send a password reset mail to the user in case the given
 * mail exists on the backend
 *
 * @param email The user entered mail
 */
export const sendPasswordReset = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err: any) {
    console.error("Error during reset mail sending!", err);
  }
};

/**
 * Helper method to logout the currently logged in user
 */
export const logout = (): void => {
  auth.signOut();
};

/**
 * Helper method to determine if an user is signed or not
 *
 * @returns true if an user is signed in, false otherwise
 */
export const userIsSignedIn = (): boolean => {
  return !!auth.currentUser;
};
