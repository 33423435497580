import { PrivateUserInformation } from "@9010/shared";
import { collectionGroup, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";
import {
  FairMarketingInfo,
  FairMarketingInfoAmounts,
  LoyaltyInfoWithUserId,
} from "../fairMarketing/FairMarketing.types";
import { extractFairMarketingAmountsFromLoyaltyInformation } from "../fairMarketing/FairMarketing.utils";
import { getPrivateInformationByUserId } from "../user/User.firebase";

/**
 * Get all Loyalty Information on the firestore with an additional userId property to identify the user
 *
 * @returns a list of Loyalty Information with user id.
 */
export const getAllLoyaltyInformation = (): Promise<
  LoyaltyInfoWithUserId[]
> => {
  return getDocs(query(collectionGroup(db, "loyaltyInformation"))).then(
    (snap) =>
      snap.docs
        .map((doc) =>
          doc.exists()
            ? { ...doc.data(), userId: doc.ref.path.split("/")[1] }
            : null
        )
        .filter(Boolean) as LoyaltyInfoWithUserId[]
  );
};

/**
 * Get Fair Marketing Information of all users which has affiliate points
 * This is used to build to fair marketing page table
 *
 * @returns an array with {@link FairMarketingInfo}
 */
export const getFairMarketingTableData = async (): Promise<
  FairMarketingInfo[]
> => {
  const loyaltyInformation = await getAllLoyaltyInformation();
  const results: FairMarketingInfo[] = [];

  for (const loyalty of loyaltyInformation) {
    const {
      balanceAmount,
      pendingAmount,
      totalAmount,
      withdrawedAmount,
    }: FairMarketingInfoAmounts =
      extractFairMarketingAmountsFromLoyaltyInformation(loyalty);
    // skip when no points exists
    if (totalAmount <= 0) continue;

    const privateInfo: PrivateUserInformation | undefined =
      await getPrivateInformationByUserId(loyalty.userId);
    if (!privateInfo) {
      console.error(
        "Found loyaltyInformation but not privateInformation for user: ",
        loyalty.userId
      );
      continue;
    }

    results.push({
      balanceAmount,
      pendingAmount,
      totalAmount,
      withdrawedAmount,
      userEmail: privateInfo.email,
      userName: `${privateInfo.firstName} ${privateInfo.lastName}`,
      userId: loyalty.userId,
    });
  }

  return results;
};
