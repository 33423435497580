import { FirebaseApp } from "firebase/app";
import { Auth } from "firebase/auth";
import { Firestore } from "firebase/firestore";
import { FirebaseStorage } from "firebase/storage";

// helper interfaces for "mocking"
interface FirebaseAuthMock extends Auth {}
interface FirebaseStorageMock extends FirebaseStorage {}
interface FirestoreMock extends Firestore {}

/**
 * This helper method initializes a mocked auth instance for the sake of
 * working with firebase in unit tests 🥴
 *
 * @returns A mocked Auth instance
 */
export const getFirebaseAuthMock = (): FirebaseAuthMock => ({
  app: undefined!,
  beforeAuthStateChanged: undefined!,
  config: undefined!,
  currentUser: undefined!,
  emulatorConfig: undefined!,
  languageCode: undefined!,
  name: undefined!,
  onAuthStateChanged: undefined!,
  onIdTokenChanged: undefined!,
  setPersistence: undefined!,
  settings: undefined!,
  signOut: undefined!,
  tenantId: undefined!,
  updateCurrentUser: undefined!,
  useDeviceLanguage: undefined!,
});

/**
 * This helper method initializes a mocked storage instance for the sake of
 * working with firebase in unit tests 🥴
 *
 * @returns A mocked storage instance
 */
export const getFirebaseStorageMock = (): FirebaseStorageMock => ({
  app: {
    automaticDataCollectionEnabled: undefined!,
    name: "",
    options: undefined!,
  },
  maxOperationRetryTime: 0,
  maxUploadRetryTime: 0,
});

/**
 * This helper method initializes a mocked store instance for the sake of
 * working with firebase in unit tests 🥴
 *
 * @returns A mocked store instance
 */
export const getFirestoreMock = (app: FirebaseApp): FirestoreMock => ({
  app: app,
  toJSON: undefined!,
  type: undefined!,
});
