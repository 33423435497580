import { MedBedLicenseType } from "@9010/shared";
import { UserMailMapping } from "./User.types";
import { medBedAxiosInstance } from "../AxiosUtil";

/**
 * List of creatable medbed license types (used only types which are available via the `generateNewMedBedLicenseForUser` function).
 * Research is currently excluded here
 */
export type CreatableMedBedLicenseType =
  | MedBedLicenseType.INITIAL
  | MedBedLicenseType.BONUS
  | MedBedLicenseType.BUSINESS
  | MedBedLicenseType.FOLLOW_UP_TEST
  | MedBedLicenseType.MOBILE_BODY
  | MedBedLicenseType.MOBILE_BODY_TEST
  | MedBedLicenseType.MOBILE_CLOTHING
  | MedBedLicenseType.MOBILE_CLOTHING_TEST
  | MedBedLicenseType.MOBILE_PHONE
  | MedBedLicenseType.MOBILE_PHONE_TEST
  | MedBedLicenseType.TESTING
  | MedBedLicenseType.EMERGENCY;

/**
 * Every {@link CreatableMedBedLicenseType} mapped to specific license options (currently to corresponding course)
 */
export const creatableMedBedLicenseMapping = {
  [MedBedLicenseType.INITIAL]: { courseId: "dLFWLbYcibEKoy4VRelm" },
  [MedBedLicenseType.BONUS]: {},
  [MedBedLicenseType.TESTING]: { courseId: "fz4ZdiISRroAeDHBFvEr" },
  [MedBedLicenseType.BUSINESS]: { courseId: "aW9X7wDwmRa4VMSpY9lc" },
  [MedBedLicenseType.FOLLOW_UP_TEST]: {},
  [MedBedLicenseType.MOBILE_BODY]: { courseId: "ygrVNsMx0p3aCn6RQyuc" },
  [MedBedLicenseType.MOBILE_BODY_TEST]: { courseId: "k0C6BsF2sRr5RF8oECx9" },
  [MedBedLicenseType.MOBILE_CLOTHING]: { courseId: "ygrVNsMx0p3aCn6RQyuc" },
  [MedBedLicenseType.MOBILE_CLOTHING_TEST]: {
    courseId: "k0C6BsF2sRr5RF8oECx9",
  },
  [MedBedLicenseType.MOBILE_PHONE]: { courseId: "ygrVNsMx0p3aCn6RQyuc" },
  [MedBedLicenseType.MOBILE_PHONE_TEST]: { courseId: "k0C6BsF2sRr5RF8oECx9" },
  [MedBedLicenseType.EMERGENCY]: {},
} satisfies Record<CreatableMedBedLicenseType, MedBedLicenseCreatorOptions>;

/**
 * Options for creating a new medbed license8
 */
export interface MedBedLicenseCreatorOptions {
  courseId?: string;
}

export const loadAllUserMailMappings = async (): Promise<UserMailMapping[]> => {
  return medBedAxiosInstance
    .get("/admin/user/all")
    .then((resp) => resp.data)
    .catch((exc) => {
      console.error("Error during user/mail mappong fetch", exc);
      return [];
    });
};

export const generateUserMailCsvMapping = (
  data: UserMailMapping[]
): string[][] => {
  const header: string[] = ["ID", "Vorname", "Nachname", "Mail"];
  const spreadData: string[][] = data.map((userMapping) => [
    userMapping.uid,
    userMapping.firstName,
    userMapping.lastName,
    userMapping.mail,
  ]);
  return [header, ...spreadData];
};
