import {
  FormEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import useSWR from "swr";
import { loadUser } from "../../utils/user/User.firebase";
import { ButtonComponent, InputComponent } from "react-component-library";
import { BusinessGeniusInformation, User } from "@9010/shared";
import {
  activateBusinessGenius,
  deactivateBusinessGenius,
} from "../../utils/businessGenius/BusinessGenius.firebase";

export const BusinessGeniusPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>();

  const { data, error, isLoading, mutate } = useSWR(
    ["user-detail", search],
    async ([, input]) => (input ? (loadUser(input) as Promise<User>) : null),
    { errorRetryCount: 0 }
  );

  /**
   * Change to search state to the searchInput state which is triggering the swr hook and loading the data
   */
  const triggerSearch = useCallback<FormEventHandler<HTMLFormElement>>(
    (evt) => {
      evt.preventDefault();
      setSearch(searchInput);
    },
    [searchInput]
  );

  /**
   * Memorize user details based on the swr hook state
   */
  const userDetails = useMemo<ReactNode>(() => {
    if (isLoading) return t("general.loading");
    if (error) {
      if (!(error instanceof Error))
        return t("pages.businessGenius.errorUnknown");
      return error.message;
    }
    if (!data) return t("pages.businessGenius.resultPlaceholder");
    return (
      <BusinessGeniusDetails
        user={data}
        information={data.businessGeniusInformation}
        mutateUser={mutate}
      />
    );
  }, [isLoading, error, data, t, mutate]);

  return (
    <div className="business-genius-page">
      <form className="business-genius-page__select" onSubmit={triggerSearch}>
        <InputComponent
          type="text"
          required
          label={t("general.email")}
          value={searchInput}
          onChange={setSearchInput}
        />
        <ButtonComponent
          type="submit"
          value={t("general.search")}
          loading={isLoading}
        />
      </form>
      <hr />
      <div className="business-genius-page__details">{userDetails}</div>
    </div>
  );
};

const BusinessGeniusDetails: React.FC<{
  user: User;
  information?: BusinessGeniusInformation;
  mutateUser?: (user: User) => void;
}> = ({ user, information, mutateUser }) => {
  const { t } = useTranslation();

  /**
   * Activate business genius for the user
   */
  const handleActivation = useCallback(async () => {
    const mutatedUser = await activateBusinessGenius(user);
    mutateUser?.(mutatedUser);
  }, [user, mutateUser]);

  /**
   * Deactivate business genius for the user
   */
  const handleDeactivation = useCallback(async () => {
    const mutatedUser = await deactivateBusinessGenius(user);
    mutateUser?.(mutatedUser);
  }, [user, mutateUser]);

  /**
   * Memorize the business genius status based on the information state
   */
  const businessGeniusStatus = useMemo(() => {
    return (
      <>
        <h4>
          <Trans
            i18nKey="pages.businessGenius.status"
            values={{
              status:
                information && !information.deactivatedDate
                  ? t("general.active")
                  : t("general.inactive"),
            }}
          />
        </h4>
        <ButtonComponent
          value={
            information && !information.deactivatedDate
              ? t("general.deactivate")
              : t("general.activate")
          }
          onClick={
            information && !information.deactivatedDate
              ? handleDeactivation
              : handleActivation
          }
        />
      </>
    );
  }, [information, handleActivation, handleDeactivation, t]);

  return (
    <>
      <div className="business-genius-page__details__header">
        <h4>
          {user.privateInformation?.firstName}{" "}
          {user.privateInformation?.lastName}
        </h4>
      </div>
      <div className="business-genius-page__details__status">
        {businessGeniusStatus}
      </div>
      {information && (
        <div className="business-genius-page__details__root-folders">
          {information.folders.length === 0 ? (
            <p>{t("pages.businessGenius.emptyFolders")}</p>
          ) : (
            information.folders
              .filter((folder) => !folder.parentUid)
              .map((folder) => (
                <BusinessGeniusFolderDetails
                  key={folder.uid}
                  information={information}
                  folderUid={folder.uid}
                />
              ))
          )}
        </div>
      )}
    </>
  );
};

const BusinessGeniusFolderDetails: React.FC<{
  information: BusinessGeniusInformation;
  folderUid: string;
}> = ({ information, folderUid }) => {
  const { t } = useTranslation();

  // the current folder to render
  const currentFolder = information.folders.find(
    (folder) => folder.uid === folderUid
  );

  // the subfolders of the current folder
  const subfolders = information.folders.filter(
    (folder) => folder.parentUid === folderUid
  );

  return (
    <div className="business-genius-page__details__folder-detail">
      <div>
        <h5>
          <Trans
            i18nKey="pages.businessGenius.detail.name"
            values={{ name: currentFolder?.name }}
            components={{ b: <b /> }}
          />
        </h5>
        <p>
          <Trans
            i18nKey="pages.businessGenius.detail.imageAmount"
            values={{ count: currentFolder?.imageAmount }}
            components={{ b: <b /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="pages.businessGenius.detail.factor"
            values={{ count: currentFolder?.factor }}
            components={{ b: <b /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="pages.businessGenius.detail.deleteTime"
            values={{ count: currentFolder?.deleteTime }}
            components={{ b: <b /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="pages.businessGenius.detail.code9010"
            values={{ code: currentFolder?.code9010 }}
            components={{ b: <b /> }}
          />
        </p>
      </div>
      {subfolders.length > 0 && (
        <div className="business-genius-page__details__folder-detail__subfolders">
          <b>{t("pages.businessGenius.detail.subfolders")}:</b>
          {subfolders.map((subfolder) => (
            <BusinessGeniusFolderDetails
              key={subfolder.uid}
              information={information}
              folderUid={subfolder.uid}
            />
          ))}
        </div>
      )}
    </div>
  );
};
