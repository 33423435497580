import { Timestamp } from "firebase/firestore";
import dayjs, { Dayjs } from "dayjs";

/**
 * Create a dayjs instance from a firestore timestamp
 *
 * @param time the firebase timestamp input
 * @returns a dayjs instance represents the same time as the input timestamp
 */
export const toDayjs = (time?: Timestamp): Dayjs =>
  dayjs(new Timestamp(time?.seconds ?? 0, time?.nanoseconds ?? 0).toDate());
