import {
  BusinessGeniusFolder,
  BusinessGeniusInformation,
  User,
  generateEmptyBusinessGeniusInformation,
} from "@9010/shared";
import {
  DocumentData,
  FieldValue,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  deleteField,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

/**
 * Activate business genius for a given user.
 *
 * It will create a new document in the user's businessGeniusInformation collection
 * or update a deactivated one.
 *
 * @param user User to activate business genius for (will be mutated)
 * @returns User with updated business genius information
 */
export const activateBusinessGenius = async (user: User): Promise<User> => {
  if (
    user.businessGeniusInformation &&
    !user.businessGeniusInformation.deactivatedDate
  )
    throw new Error(
      "Business Genius already enabled (information exists in user)"
    );

  const geniusInformationCol = collection(
    db,
    "users",
    user.uid,
    "businessGeniusInformation"
  );

  const docSnapshot: QueryDocumentSnapshot<DocumentData> | undefined =
    await getDocs(geniusInformationCol).then((snapshot) => snapshot.docs[0]);

  if (docSnapshot?.exists()) {
    if (!user.businessGeniusInformation)
      throw new Error(
        "Local business genius information are not sync with backend"
      );

    const updatedProps = {
      activatedDate: Timestamp.now(),
      deactivatedDate: deleteField(),
    } satisfies Partial<
      | BusinessGeniusInformation
      | Record<keyof BusinessGeniusInformation, FieldValue>
    >;

    await updateDoc(doc(db, docSnapshot.ref.path), updatedProps);

    delete user.businessGeniusInformation.deactivatedDate;
    user.businessGeniusInformation.activatedDate = updatedProps.activatedDate;
    return user;
  }

  const geniusInformation = generateEmptyBusinessGeniusInformation();
  const newlyCreatedDocRef = await addDoc(
    geniusInformationCol,
    geniusInformation
  );

  await setDoc(doc(db, geniusInformationCol.path, newlyCreatedDocRef.id), {
    ...geniusInformation,
    id: newlyCreatedDocRef.id,
  });

  user.businessGeniusInformation = geniusInformation;

  return user;
};

/**
 * Deactivate business genius for a given user.
 *
 * It will update the user's businessGeniusInformation document with a deactivatedDate
 * If the user has no businessGeniusInformation, it will do nothing.
 *
 * @param user User to deactivate business genius for (will be mutated)
 * @returns User with updated business genius information
 */
export const deactivateBusinessGenius = async (user: User): Promise<User> => {
  if (!user.businessGeniusInformation) return user;

  const geniusInformationCol = collection(
    db,
    "users",
    user.uid,
    "businessGeniusInformation"
  );

  const docSnapshot = await getDocs(geniusInformationCol).then(
    (snapshot) => snapshot.docs[0]
  );

  const updatedProps: Partial<BusinessGeniusInformation> = {
    deactivatedDate: Timestamp.now(),
  };

  await updateDoc(doc(db, docSnapshot.ref.path), updatedProps);

  user.businessGeniusInformation = {
    ...user.businessGeniusInformation,
    ...updatedProps,
  };

  return user;
};

/**
 * Load the business genius information for a given user.
 *
 * It will load the business genius information and all the folders.
 *
 * @param userPath Path to the user on firestore (e.g. "users/{user_uid}")
 * @returns Business genius information for the given user or undefined if not found
 */
export const getBusinessGeniusInformation = async (
  userPath: string
): Promise<BusinessGeniusInformation | undefined> => {
  const businessGeniusCol = collection(
    db,
    userPath,
    "businessGeniusInformation"
  );
  const docSnapshot: QueryDocumentSnapshot<DocumentData> | undefined = (
    await getDocs(businessGeniusCol)
  ).docs[0];

  if (!docSnapshot?.exists()) return undefined;

  const information: BusinessGeniusInformation =
    docSnapshot.data() as BusinessGeniusInformation;

  const foldersSnapshot = await getDocs(
    collection(db, docSnapshot.ref.path, "folders")
  );

  const folders: BusinessGeniusFolder[] = foldersSnapshot.docs.map(
    (doc) => doc.data() as BusinessGeniusFolder
  );

  information.folders = folders;

  return information;
};
