import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { WaitFor } from "../../utils/swr/SWR.utils";
import {
  getUserCountHistory,
  loadUserCount,
} from "../../utils/user/User.firebase";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";
import { UserHistoryEntry } from "@9010/shared";
import dayjs from "dayjs";

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-page">
      <h1>{t("pages.dashboard.title")}</h1>
      <hr />
      <UserCountChart />
    </div>
  );
};

/**
 * User Count Chart which contains the current user count and a historic chart
 */
const UserCountChart: React.FC = () => {
  const { t } = useTranslation();
  const userCountLatest = useSWR("user-count", () => loadUserCount());
  const userCountHistory = useSWR("user-count-history", () =>
    getUserCountHistory()
  );

  return (
    <>
      <h3>
        {t("pages.dashboard.userCount.current")}{" "}
        <b>
          <WaitFor data={userCountLatest} loader={"..."} />
        </b>
      </h3>
      <WaitFor data={userCountHistory} loader={"..."}>
        {({ data }) => (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.5} />
              <XAxis
                tickMargin={15}
                dataKey={({ date }: UserHistoryEntry) => date.toDate()}
                tickFormatter={(date: Date) => dayjs(date).format("DD.MM.")}
              />
              <YAxis
                tickMargin={15}
                allowDecimals={false}
                domain={[
                  (dataMin: number) => dataMin,
                  (dataMax: number) => dataMax,
                ]}
              />
              <Tooltip />
              <Line
                type="monotone"
                activeDot
                dot={false}
                dataKey="count"
                stroke="#f89a26"
                strokeWidth={3}
                name={t("pages.dashboard.userCount.amount")}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </WaitFor>
    </>
  );
};
