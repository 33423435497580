import { SWRConfiguration, SWRResponse } from "swr";
import { ReactNode } from "react";

export const swrConfig: SWRConfiguration = {};

/**
 * A helper which can wait for a swr to resolve and show a loader in the meanwhile.
 * Children can be a function whichs returns the full {@link SWRResponse} as we get via the hook but with fulfilled data.
 * If no children is given then the resolved data will be returned.
 *
 * KEEP IN MIND: When the resolved swr data is not compatible with the {@link ReactNode} then the client throws an error!
 */
export const WaitFor = <T extends SWRResponse>({
  data,
  children,
  loader,
}: {
  data: T;
  children?: (data: DefinedSWRResponse<T>) => ReactNode;
  loader?: ReactNode;
}) => {
  if (data.isLoading || !data.data) return <>{loader ?? "Loading..."}</>;
  return <>{children ? children(data) : data.data}</>;
};

// make the data field of the swr response defined
type DefinedSWRResponse<T extends SWRResponse> = Omit<T, "data"> & {
  data: NonNullable<T["data"]>;
};
