import useSWR from "swr";
import { getFairMarketingTableData } from "../../utils/affiliate/Affiliate.firebase";
import { generateFairMarketingTable } from "../../utils/fairMarketing/FairMarketing.utils";
import { WaitFor } from "../../utils/swr/SWR.utils";

export const FairMarketingPage: React.FC = () => {
  const fairMarketingData = useSWR("fair-marketing-data", () =>
    getFairMarketingTableData()
  );

  return (
    <div className="fair-marketing-page">
      <WaitFor data={fairMarketingData}>
        {({ data }) => generateFairMarketingTable(data)}
      </WaitFor>
    </div>
  );
};
