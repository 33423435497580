import { ReactNode, useCallback } from "react";
import { ButtonComponent } from "react-component-library";
import { useTranslation } from "react-i18next";
import { useUser } from "../../utils/auth/UserContext";
import { logout } from "../../utils/FirebaseUtils";

export const AccountPage: React.FC = () => {
  const { user } = useUser();
  const { t } = useTranslation();

  /**
   * A helper to create a `<p />` tag with the name and value of a user property.
   *
   * @param userProp the user property to display
   * @returns a `p` tag with the needed information
   */
  const createRow = useCallback<(userProp: keyof typeof user) => ReactNode>(
    (userProp) => (
      <p>
        <b>{t(`pages.account.${userProp}`)}:</b> {user[userProp]}
      </p>
    ),
    [user, t]
  );

  return (
    <div className="account-page">
      <h1>{t("pages.account.title")}</h1>
      {createRow("uid")}
      {createRow("email")}
      {createRow("firstName")}
      {createRow("lastName")}
      <ButtonComponent
        className="account-page__logout-button"
        value={t("general.logout")}
        color="white"
        onClick={() => logout()}
      />
    </div>
  );
};
