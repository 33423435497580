import {
  MedBed,
  MedBedLicense,
  User,
  UserLoyaltyInformation,
} from "@9010/shared";
import {
  FormEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  ButtonComponent,
  InputComponent,
  PopupComponent,
} from "react-component-library";
import useSWR, { KeyedMutator } from "swr";
import {
  addNewLicenseToUser,
  deleteMedBedLicense,
  loadUser,
} from "../../utils/user/User.firebase";
import { useTranslation } from "react-i18next";
import { toDayjs } from "../../utils/dayjs/Dayjs.util";
import { Data } from "react-csv/components/CommonPropTypes";
import { ExtendedUser } from "../../utils/user/User.types";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";
import { CSVLink } from "react-csv";
import {
  creatableMedBedLicenseMapping,
  CreatableMedBedLicenseType,
  generateUserMailCsvMapping,
  loadAllUserMailMappings,
} from "../../utils/user/UserDetails.utils";

// outsourced date format used for this page
const DATE_FORMAT = "DD.MM.YY HH:mm";

export const UserDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>();
  const [exportButtonLoading, toggleExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState<Data>([]);
  const { data, error, isLoading, mutate } = useSWR(
    ["user-detail", search],
    async ([, input]) => (input ? loadUser(input) : null),
    { errorRetryCount: 0 }
  );

  /**
   * Change to search state to the searchInput state which is triggering the swr hook and loading the data
   */
  const triggerSearch = useCallback<FormEventHandler<HTMLFormElement>>(
    (evt) => {
      evt.preventDefault();
      setSearch(searchInput);
    },
    [searchInput]
  );

  /**
   * Memorize user details based on the swr hook state
   */
  const userDetails = useMemo<ReactNode>(() => {
    if (isLoading) return t("pages.userDetails.loading");
    if (error) {
      if (!(error instanceof Error)) return t("pages.userDetails.errorUnknown");
      return error.message;
    }
    if (!data) return t("pages.userDetails.resultPlaceholder");
    return <UserDetails user={data} mutate={mutate} />;
  }, [isLoading, error, data, t, mutate]);

  const generateUserCsv = (): void => {
    toggleExportLoading(true);
    loadAllUserMailMappings().then((loadedData) => {
      const localExportData: string[][] =
        generateUserMailCsvMapping(loadedData);
      setExportData(localExportData);
      console.log(exportData);
      window.setTimeout(() => {
        let element: HTMLElement = document.getElementById(
          "exportDataLinkButton"
        ) as HTMLElement;
        element.click();
        toggleExportLoading(false);
      }, 500);
    });
  };

  return (
    <div className="user-detail-page">
      <form className="user-detail-page__select" onSubmit={triggerSearch}>
        <InputComponent
          type="text"
          required
          label={t("general.email")}
          value={searchInput}
          onChange={setSearchInput}
        />
        <ButtonComponent
          type="submit"
          value={t("general.search")}
          loading={isLoading}
        />
        <ButtonComponent
          type="button"
          value={"Alle exportieren"}
          onClick={generateUserCsv}
          loading={exportButtonLoading}
        />
        <CSVLink
          data={exportData}
          id="exportDataLinkButton"
          asyncOnClick={true}
          style={{ visibility: "hidden" }}
          filename={`9010_user.csv`}
        />
      </form>
      <hr />
      <div className="user-detail-page__content">{userDetails}</div>
    </div>
  );
};

/**
 * User details as seperate component
 */
const UserDetails: React.FC<{
  user: ExtendedUser;
  mutate: KeyedMutator<ExtendedUser | null>;
}> = ({ user, mutate }) => {
  const { t } = useTranslation();
  const [showLicenseCreator, toggleLicenseCreator] = useState<boolean>(false);
  const [errorPopupContent, setErrorPopupContent] = useState<string>();

  return (
    <>
      <div className="user-detail-page__general">
        <div>
          <h4>
            {user.privateInformation?.firstName}{" "}
            {user.privateInformation?.lastName}
          </h4>
          <p>
            <b>{t("pages.userDetails.general.email")}:</b>{" "}
            {user.privateInformation?.email}
          </p>
          <p>
            <b>{t("pages.userDetails.general.accountId")}:</b> {user.uid}
          </p>
          <p>
            <b>{t("pages.userDetails.general.firstName")}:</b>{" "}
            {user.privateInformation?.firstName}
          </p>
          <p>
            <b>{t("pages.userDetails.general.lastName")}:</b>{" "}
            {user.privateInformation?.lastName}
          </p>
        </div>
        <div className="user-detail-page__general__avatar">
          <img src={user.avatarUrl} alt="avatar" />
        </div>
      </div>
      <h4>{t("pages.userDetails.medbedInformation")}</h4>
      <div className="user-detail-page__medbed-license">
        {user.medBedInformation.licenses.map((license) => (
          <MedBedLicenseDetails
            license={license}
            key={license.uid}
            user={user}
            mutate={mutate}
          />
        ))}
        <ButtonComponent
          value={t("pages.userDetails.newMedBedLicense")}
          onClick={() => toggleLicenseCreator(true)}
        />
        {showLicenseCreator && (
          <MedBedLicenseCreator
            user={user}
            onClose={() => toggleLicenseCreator(false)}
            onCreation={async () => {
              await mutate();
              toggleLicenseCreator(false);
            }}
          />
        )}
        <PopupComponent
          show={!!errorPopupContent}
          onClose={() => setErrorPopupContent(undefined)}
        >
          {errorPopupContent}
        </PopupComponent>
      </div>
      <h4>{t("pages.userDetails.loyaltyInformation")}</h4>
      <LoyaltyDetails loyalty={user.loyaltyInformation} />
    </>
  );
};

/**
 * The MedBed license creator popup to select a medbed license to add
 */
const MedBedLicenseCreator: React.FC<{
  user: User;
  onClose: () => void;
  onCreation: () => Promise<void>;
}> = ({ onClose, onCreation, user }) => {
  const { t } = useTranslation();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(1);

  /**
   * Function to create the selected amount of medbed licences for the currently displayed user
   *
   * @param type the license type to add
   * @returns a promise which resolves after successfully finished
   */
  const createNewLicense = useCallback(
    async (type: CreatableMedBedLicenseType): Promise<void> => {
      toggleLoading(true);
      await addNewLicenseToUser(user.uid, type, amount).then(() =>
        onCreation()
      );
    },
    [onCreation, user.uid, amount]
  );

  return (
    <PopupComponent
      show
      onClose={onClose}
      title={t("pages.userDetails.selectLicense")}
    >
      <InputComponent
        label={t("pages.userDetails.medbed.creationAmount")}
        type="number"
        value={amount}
        onNumberChange={(num) => setAmount(Math.max(1, num))}
        className="user-detail-page__medbed-license-creator__amount"
      />
      {Object.keys(creatableMedBedLicenseMapping).map((type) => (
        <ButtonComponent
          key={type}
          value={t(`pages.userDetails.medbed.types.${type}`, {
            defaultValue: type,
          })}
          onClick={() => createNewLicense(type as CreatableMedBedLicenseType)}
          disabled={isLoading}
        />
      ))}
    </PopupComponent>
  );
};

/**
 * A component which show a medbed license in detail
 */
const MedBedLicenseDetails: React.FC<{
  license: MedBedLicense;
  user: User;
  mutate: KeyedMutator<ExtendedUser | null>;
}> = ({ license, user, mutate }) => {
  const { t } = useTranslation();
  const [showDeletePopup, toggleDeletePopup] = useState<boolean>(false);
  const [deletePopupLoading, toggleDeletePopupLoading] =
    useState<boolean>(false);

  return (
    <div className="user-detail-page__medbed-license__entry">
      <TrashIcon
        className="user-detail-page__medbed-license__trash-icon"
        onClick={() => toggleDeletePopup(true)}
      />
      <h5>
        {t("pages.userDetails.medbed.type")}:{" "}
        <b>
          {t(`pages.userDetails.medbed.types.${license.type}`, {
            defaultValue: license.type,
          })}
        </b>
      </h5>
      <p>
        {t("pages.userDetails.medbed.created")}:{" "}
        <b>{toDayjs(license.createDate).format(DATE_FORMAT)}</b>
      </p>
      <p>{t("pages.userDetails.medbed.connectedBeds")}:</p>
      <div className="user-detail-page__medbed">
        {license.connectedBeds.map((bed) => (
          <MedBedDetails medbed={bed} key={bed.uid} />
        ))}
      </div>
      <PopupComponent
        show={showDeletePopup}
        loading={deletePopupLoading}
        onClose={() => toggleDeletePopup(false)}
        onClick={() => {
          toggleDeletePopupLoading(true);
          deleteMedBedLicense(user, license).then(() => {
            toggleDeletePopup(false);
            toggleDeletePopupLoading(false);
            mutate();
          });
        }}
        title="ACHTUNG"
        titleClass="user-detail-page__medbed-license__trash-popup__title"
        buttonText="Löschen"
        buttonColor="#ce0b65"
      >
        Diese Aktion kann nicht rückgängig gemacht werden! Soll die Lizenz{" "}
        <b>
          {t(`pages.userDetails.medbed.types.${license.type}`, {
            defaultValue: license.type,
          })}
        </b>{" "}
        wirklich gelöscht werden?
      </PopupComponent>
    </div>
  );
};

/**
 * A component which show a medbed in detail
 */
const MedBedDetails: React.FC<{ medbed: MedBed }> = ({ medbed }) => {
  const { t } = useTranslation();
  return (
    <div className="user-detail-page__medbed__entry">
      <p>
        {t("pages.userDetails.medbed.name")}:
        <br />
        <b>{medbed.name}</b> ({medbed.index + 1})
      </p>
      <p>
        {t("pages.userDetails.medbed.status")}:
        <br />
        <b>
          {t(`pages.userDetails.medbed.statusTypes.${medbed.status}`, {
            defaultValue: medbed.status,
          })}
        </b>
      </p>
      <p>
        {t("pages.userDetails.medbed.created")}:
        <br />
        <b>{toDayjs(medbed.createDate).format(DATE_FORMAT)}</b>
      </p>
      <p>
        {t("pages.userDetails.medbed.updated")}:
        <br />
        <b>{toDayjs(medbed.lastUpdated).format(DATE_FORMAT)}</b>
      </p>
    </div>
  );
};

/**
 * A component which shows the loyalty information in detail
 */
const LoyaltyDetails: React.FC<{ loyalty: UserLoyaltyInformation }> = ({
  loyalty,
}) => {
  const { t } = useTranslation();
  return (
    <div className="user-detail-page__loyalty">
      <p>
        <b>{t("pages.userDetails.loyalty.affiliatePoints")}:</b>
        <br />
        {loyalty.affiliatePoints ?? 0}
      </p>
      <p>
        <b>{t("pages.userDetails.loyalty.medBedPoints")}:</b>
        <br />
        {loyalty.medBedPoints ?? 0}
      </p>{" "}
      <p>
        <b>{t("pages.userDetails.loyalty.virtualCubePoints")}:</b>
        <br />
        {loyalty.virtualCubePoints ?? 0}
      </p>
    </div>
  );
};
