import { User } from "@9010/shared";

/**
 * An AdminUser represents the user using the admin client.
 * Here we dont need as much details as in {@link User}
 */
export interface AdminUser {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
}

/** user with an additional avatarUrl property */
export type ExtendedUser = User & { avatarUrl: string };

/**
 * Helper interface which mapps the user information on their mail
 */
export interface UserMailMapping {
  uid: string;
  firstName: string;
  lastName: string;
  mail: string;
}

/**
 * An object with all static academy courses
 */
export const StaticCourses = {
  TESTING: process.env.REACT_APP_ACADEMY_MEDBED_8HOURS_INTRO_ID as string,
  EMERGENCY: process.env.REACT_APP_ACADEMY_MEDBED_EMERGENCY_INTRO_ID as string,
  MEDTESTINGBED_MOBILE_TEST: process.env
    .REACT_APP_ACADEMY_MEDBED_MOBILE_TEST_ID as string,
  MOBILE_BODY_TEST: process.env
    .REACT_APP_ACADEMY_MEDBED_MOBILE_SUB_ID as string,
  MOBILE_PHONE_TEST: process.env
    .REACT_APP_ACADEMY_MEDBED_MOBILE_SUB_ID as string,
  MOBILE_CLOTHING_TEST: process.env
    .REACT_APP_ACADEMY_MEDBED_MOBILE_SUB_ID as string,
  MOBILE_BODY: process.env.REACT_APP_ACADEMY_MEDBED_MOBILE_SUB_ID as string,
  MOBILE_PHONE: process.env.REACT_APP_ACADEMY_MEDBED_MOBILE_SUB_ID as string,
  MOBILE_CLOTHING: process.env.REACT_APP_ACADEMY_MEDBED_MOBILE_SUB_ID as string,
  INITIAL: process.env.REACT_APP_ACADEMY_MEDBED_LIFETIME_INTRO_ID as string,
  BUSINESS: process.env.REACT_APP_ACADEMY_MEDBED_BUSINESS_INTRO_ID as string,
};
