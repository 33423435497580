import { useTranslation } from "react-i18next";
import { LoginForm } from "../components/LoginForm";

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="login-page">
      <h1>{t("app.clientName")}</h1>
      <h2>{t("pages.login.title")}</h2>
      <LoginForm />
    </div>
  );
};
