import { MedBedInformation, MedBedLicenseType } from "@9010/shared";

export const userHasAnotherMedBedLicenseOfSameType = (
  medBedInfo: MedBedInformation,
  type: MedBedLicenseType
): boolean => {
  if (!medBedInfo.licenses) return false;
  return (
    medBedInfo.licenses.filter((license) => license.type === type).length > 0
  );
};
