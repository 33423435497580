import React, { useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AccountPage } from "./pages/menu/AccountPage";
import { LoginPage } from "./pages/LoginPage";
import { DashboardPage } from "./pages/menu/DashboardPage";
import { PrivateLayout } from "./components/PrivateLayout";
import { UserProvider } from "./utils/auth/UserContext";
import { NotFoundPage } from "./pages/NotFoundPage";
import { LoaderComponent } from "react-component-library";
import { FairMarketingPage } from "./pages/menu/FairMarketingPage";
import { UserDetailPage } from "./pages/menu/UserDetailPage";
import { BusinessGeniusPage } from "./pages/menu/BusinessGeniusPage";

const App: React.FC = () => {
  /**
   * Memorize routes for logged in users
   */
  const routes = useMemo(
    () => (
      <Routes>
        <Route path="/" element={<PrivateLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="user" element={<UserDetailPage />} />
          <Route path="fairmarketing" element={<FairMarketingPage />} />
          <Route path="businessgenius" element={<BusinessGeniusPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    ),
    []
  );

  return (
    <Router>
      <UserProvider fallback={<LoginPage />} loading={<LoaderComponent />}>
        {routes}
      </UserProvider>
    </Router>
  );
};

export default App;
