import { classNames } from "@9010/shared";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../utils/FirebaseUtils";

interface MenuEntry {
  name: string;
  path: string;
}

export const Navigation: React.FC = () => {
  const { t } = useTranslation();

  /**
   * Memorized list of menu entries a user can click
   */
  const menuEntries = useMemo<MenuEntry[]>(() => {
    return [
      { name: t("pages.navigation.fairMarketing"), path: "/fairmarketing" },
      { name: t("pages.navigation.user"), path: "/user" },
      { name: t("pages.navigation.businessGenius"), path: "/businessgenius" },
    ];
  }, [t]);

  return (
    <div className="navigation-component">
      <div className="navigation-component__title">
        <Link to="/">
          <div className="navigation-component__title__name">
            {t("app.clientName")}
          </div>
        </Link>
      </div>
      <div className="navigation-component__menu">
        {menuEntries.map((entry) => (
          <NavLink
            key={entry.path}
            to={entry.path}
            className={({ isActive }) =>
              classNames(
                "navigation-component__menu__entry",
                isActive && "active"
              )
            }
          >
            {entry.name}
          </NavLink>
        ))}
      </div>
      <div className="navigation-component__account">
        <NavLink
          to="/account"
          className={({ isActive }) =>
            classNames(
              "navigation-component__account__entry",
              isActive && "active"
            )
          }
        >
          {t("pages.navigation.account")}
        </NavLink>
        <button
          className={"navigation-component__account__entry"}
          onClick={() => logout()}
        >
          {t("pages.navigation.logout")}
        </button>
      </div>
    </div>
  );
};
