import {
  createUserWithEmailAndPassword,
  User as FirebaseUser,
  UserCredential,
} from "firebase/auth";
import {
  addDoc,
  arrayUnion,
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  getCountFromServer,
  getDoc,
  getDocs,
  Query,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  ref,
  StorageReference,
  uploadBytes,
} from "firebase/storage";
import { auth, db, storage } from "../../firebase";
import {
  AcademyCourse,
  AffiliateInformation,
  CourseInformation,
  generateEmptyUserLoyaltyInformation,
  generateEmptyVirtualCubeInformation,
  generateNewMedBedLicenseForUser,
  MedBedInformation,
  Payment,
  PrivateUserInformation,
  User,
  UserLoyaltyInformation,
  UserSettings,
  UserType,
  VirtualCubeCategory,
  VirtualCubeInformation,
  VirtualCubeProduct,
  UserHistoryEntry,
  MedBedLicense,
} from "@9010/shared";
import { ExtendedUser, StaticCourses } from "./User.types";
import {
  creatableMedBedLicenseMapping,
  CreatableMedBedLicenseType,
  MedBedLicenseCreatorOptions,
} from "./UserDetails.utils";
import { getBusinessGeniusInformation } from "../businessGenius/BusinessGenius.firebase";
import { userHasAnotherMedBedLicenseOfSameType } from "./User.utils";

/**
 * The database reference for user
 */
const userDatabase: string = process.env.REACT_APP_USER_DATABASE!;

/**
 * API method to init a new user based upon mail and password
 * and persist it on the database.
 *
 * @param newUser The new user instance
 * @param password The user entered password
 * @returns true if successful, false otherwise
 */
export const registerNewUserWithPassword = async (
  newUser: User,
  password: string
): Promise<boolean> => {
  try {
    const newEntry: UserCredential = await createUserWithEmailAndPassword(
      auth,
      newUser.privateInformation!.email,
      password
    );
    const user: FirebaseUser = newEntry.user;
    const { privateInformation, ...publicInformation } = newUser;
    const userRef: DocumentReference = await addDoc(
      collection(db, userDatabase),
      {
        ...publicInformation,
        uid: user.uid,
      } as User
    );
    const userPrivateRef: CollectionReference = collection(
      db,
      userDatabase,
      userRef.id,
      "privateInformation"
    );
    await addDoc(userPrivateRef, {
      ...privateInformation,
      authProvider: "local",
    } as PrivateUserInformation);
    return true;
  } catch (err: any) {
    console.error("Error during user creation", err);
    return false;
  }
};

/**
 * API method to upload a new logo image. It sets implicitly the
 * user id as filename and deletes any old images with the same id
 *
 * @param image The image to upload
 * @param userId The userid of the uploader
 * @returns A promise of the uploaded full path
 */
export const uploadLogoImage = async (
  image: File,
  userId: string
): Promise<string> => {
  // try to delete the old logo if it is applicable
  const oldLogo = ref(storage, `logos/${userId}`);
  deleteObject(oldLogo);
  // upload the new one
  const logoRef = ref(storage, `logos/${userId}`);
  return uploadBytes(logoRef, image)
    .then((snapshot) => snapshot.ref.fullPath)
    .catch((exc) => {
      console.error("Error during logo upload", exc);
      return "";
    });
};

/**
 * Helper to load url to logo of provider id for display
 * @param providerId
 * @returns path to Logo of given provider Id
 */
export const getLogoUrl = async (providerId: string): Promise<string> => {
  const storageRef: StorageReference = ref(storage, `logos/${providerId}`);
  const url = await getDownloadURL(storageRef);
  return url;
};

/**
 * API method to load many providers identified by their uid
 *
 * @param uids The uids of the provider to load
 * @returns The list of found provider
 */
export const loadManyUserByUids = async (uids: string[]): Promise<User[]> => {
  const userQuery: Query<DocumentData> = query(
    collection(db, userDatabase),
    where("uid", "in", uids)
  );
  const targetArray: User[] = [];
  return getDocs(userQuery).then((resp) => {
    if (resp.empty) return [];
    resp.forEach((user) => targetArray.push(user.data() as User));
    return targetArray;
  });
};

/**
 * API method to load the count of registered users
 * (calculated by the doc size in the users collection)
 *
 * @returns promised number as user count
 */
export const loadUserCount = async (): Promise<number> => {
  const result = await getCountFromServer(query(collection(db, "users")));
  return result.data().count;
};

/**
 * Get the private Information of a given user from firestore
 *
 * @param userId the user id of which we want the private information
 * @returns the private information or undefined in case of not found
 */
export const getPrivateInformationByUserId = async (
  userId: string
): Promise<PrivateUserInformation | undefined> => {
  return getDocs(
    query(collection(db, "users", userId, "privateInformation"))
  ).then((docs) => docs.docs[0]?.data() as PrivateUserInformation | undefined);
};

/**
 * Load a user with extra avatarUrl from a email or userUid.
 * This is used in the user detail page to show a specific user.
 *
 * @param userIdOrEmail the email or userUid of the desired user (detects emails automatically)
 * @returns the user with avatar url or throws an error (also if not found)
 */
export const loadUser = async (
  userIdOrEmail: string
): Promise<ExtendedUser> => {
  const userId: string | null = userIdOrEmail.includes("@")
    ? await getUserIdByEmail(userIdOrEmail)
    : userIdOrEmail;

  if (!userId) throw new Error("User does not exists");

  const userRoot = await getDoc(doc(db, "users", userId));
  if (!userRoot.exists()) throw new Error("User does not exists");

  const userRootData = userRoot.data() as { uid: string; type: UserType };

  // Copyied from community app
  const [
    privateInformation,
    medBedInformation,
    courseInformation,
    userSettings,
    loyaltyInformation,
    virtualCubeInformation,
    payments,
    affiliateInformation,
    avatarUrl,
    businessGeniusInformation,
  ] = await Promise.all([
    getDocs(collection(db, userRoot.ref.path, "privateInformation")).then(
      (privateDocs) => privateDocs.docs[0]?.data() as PrivateUserInformation
    ),
    getDocs(collection(db, userRoot.ref.path, "medBedInformation")).then(
      (medbedDocs) => medbedDocs.docs[0]?.data() as MedBedInformation
    ),
    getDocs(collection(db, userRoot.ref.path, "courseInformation")).then(
      (courseDocs) =>
        courseDocs.docs.map((doc) => doc.data() as CourseInformation)
    ),
    getDocs(collection(db, userRoot.ref.path, "userSettings")).then(
      (userSettingsDocs) => userSettingsDocs.docs[0]?.data() as UserSettings
    ),
    getDocs(collection(db, userRoot.ref.path, "loyaltyInformation")).then(
      (userLoyaltyDocs) => {
        if (userLoyaltyDocs.empty) return generateEmptyUserLoyaltyInformation();
        return userLoyaltyDocs.docs[0]?.data() as UserLoyaltyInformation;
      }
    ),
    getDocs(collection(db, userRoot.ref.path, "virtualCubeInformation")).then(
      async (virtualCubeInfo) => {
        if (virtualCubeInfo.empty) return generateEmptyVirtualCubeInformation();
        const categories: VirtualCubeCategory[] = await getDocs(
          collection(
            db,
            virtualCubeInfo.docs[0].ref.path,
            "virtualCubeCategory"
          )
        ).then((categories) =>
          categories.docs.map((entry) => entry.data() as VirtualCubeCategory)
        );

        const products: VirtualCubeProduct[] = await getDocs(
          collection(db, virtualCubeInfo.docs[0].ref.path, "virtualCubeProduct")
        ).then((products) =>
          products.docs.map((entry) => entry.data() as VirtualCubeProduct)
        );

        return {
          ...virtualCubeInfo.docs[0].data(),
          categories,
          products,
        } as VirtualCubeInformation;
      }
    ),
    getDocs(collection(db, userRoot.ref.path, "payments")).then((paymentDocs) =>
      paymentDocs.docs.map((doc) => doc.data() as Payment)
    ),
    getDocs(collection(db, userRoot.ref.path, "affiliateInformation")).then(
      (snapshot) =>
        snapshot.docs.map((doc) => doc.data() as AffiliateInformation)
    ),
    getAvatarUrl(userId),
    getBusinessGeniusInformation(userRoot.ref.path),
  ]);

  const user: ExtendedUser = {
    uid: userRootData.uid,
    type: userRootData.type,
    privateInformation,
    medBedInformation,
    courseInformation,
    userSettings,
    loyaltyInformation,
    virtualCubeInformation,
    payments,
    affiliateInformation,
    avatarUrl,
    businessGeniusInformation,
  };

  return user;
};

/**
 * Resolve the userUid by a given email
 *
 * @param email the email from which we want the userUid
 * @returns the uid of the user or null if no user was found
 */
export const getUserIdByEmail = async (
  email: string
): Promise<string | null> => {
  const snapshot = await getDocs(
    query(
      collectionGroup(db, "privateInformation"),
      where("email", "==", email)
    )
  );
  const userDoc = snapshot.docs[0];
  return userDoc?.exists() ? userDoc.ref.path.split("/")[1] : null;
};

/**
 * Helper to load url to logo of provider id for display
 * @param userId
 * @returns path to Logo of given provider Id
 */
export const getAvatarUrl = async (userId: string): Promise<string> => {
  const storageRef: StorageReference = ref(storage, `avatar/${userId}`);
  return getDownloadURL(storageRef).catch((exc) => {
    console.info("There seems to be no avatar", exc);
    return "";
  });
};

/**
 * Create a new medbed license for a given user
 *
 * @param userUid the user which receives the license
 * @param type the type of the license
 * @param amount the amount of licenses to create (default = 1)
 * @returns true if success and false if the user is not allowed to get this license
 */
export const addNewLicenseToUser = async (
  userUid: string,
  type: CreatableMedBedLicenseType,
  amount: number = 1
): Promise<boolean> => {
  const options: MedBedLicenseCreatorOptions =
    creatableMedBedLicenseMapping[type];

  const medBedInformationCol = collection(
    db,
    "users",
    userUid,
    "medBedInformation"
  );

  const medbedInfoDoc = await getDocs(medBedInformationCol).then(
    (result) => result.docs[0]
  );

  const medbedInfo: MedBedInformation =
    medbedInfoDoc.data() as MedBedInformation;

  const courseCol = collection(db, "users", userUid, "courseInformation");
  const hasCourse = options.courseId
    ? await getDocs(courseCol)
        .then((snap) => (snap.docs ?? []).map((doc) => doc.data()))
        .then((docs) =>
          docs.some((course) => course.courseId === options.courseId)
        )
    : true;

  if (!hasCourse && options.courseId) {
    const academyCourse = await getAcademyCourse(options.courseId);

    if (!academyCourse)
      throw new Error(`Course with id "${options.courseId}" doesn't exists`);

    const newCourse: CourseInformation = {
      completed: false,
      courseId: options.courseId,
      createDate: Timestamp.now(),
      lastUpdate: Timestamp.now(),
      length: academyCourse.steps,
      lessonProgresses: [],
      version: 1,
      popupConfirmed: false,
    };
    await addDoc(courseCol, newCourse);
  }

  for (let i = 0; i < amount; i++) {
    const newLicense = generateNewMedBedLicenseForUser(
      type,
      medbedInfo.licenses
    );
    if (!newLicense) return false;

    await updateDoc(medbedInfoDoc.ref, { licenses: arrayUnion(newLicense) });
  }

  return true;
};

/**
 * Get an academy course from the firestore by id
 *
 * @param courseId the uid of the course
 * @returns the academy course or undefined in case not found
 */
export const getAcademyCourse = (
  courseId: string
): Promise<AcademyCourse | undefined> => {
  return getDocs(
    query(collection(db, "academy_courses"), where("uid", "==", courseId))
  ).then((snap) => snap.docs[0]?.data() as AcademyCourse | undefined);
};

/**
 * Get all user history objects from the firestore
 *
 * @returns promised array of {@link UserHistoryEntry}
 */
export const getUserCountHistory = async (): Promise<UserHistoryEntry[]> => {
  return getDocs(collection(db, "user_count")).then((snap) =>
    snap.docs.map((doc) => doc.data() as UserHistoryEntry)
  );
};

export const deleteMedBedLicense = async (
  user: User,
  medBedLicense: MedBedLicense
): Promise<boolean> => {
  // update the actual medbed license in the user
  const medBedInformationDoc = (
    await getDocs(collection(db, `users/${user.uid}/medBedInformation`))
  )?.docs[0];
  const medBedInfo: MedBedInformation =
    medBedInformationDoc.data() as MedBedInformation;
  if (!medBedInfo || !medBedInfo.licenses) return false;
  const medBedRef = medBedInformationDoc.ref;
  const updatedMedBedInfo: MedBedInformation = {
    ...medBedInfo,
    licenses: medBedInfo.licenses.filter(
      (license) => license.uid !== medBedLicense.uid
    ),
  };
  await updateDoc(medBedRef, updatedMedBedInfo as DocumentData);
  // remove the corresponding course if applicable
  if (
    !userHasAnotherMedBedLicenseOfSameType(
      updatedMedBedInfo,
      medBedLicense.type
    )
  ) {
    const courseId: string | undefined = Object.entries(StaticCourses).find(
      (course) => course[0] === medBedLicense.type
    )?.[1];
    if (!courseId) return true;
    const q = query(
      collection(db, `users/${user.uid}/courseInformation`),
      where("courseId", "==", courseId)
    );
    const doc = (await getDocs(q)).docs?.[0];
    if (doc) await deleteDoc(doc.ref);
  }

  return true;
};
