import { collection, getDoc, doc, getDocs } from "firebase/firestore";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { AdminUser } from "../user/User.types";

/**
 * The context model which is available in the useUser hook
 */
interface UserContextData {
  user: AdminUser;
  update: () => Promise<void>;
}

const UserContext = createContext<UserContextData>(undefined!);

export const UserProvider: React.FC<{
  fallback?: ReactNode;
  loading?: ReactNode;
  children: ReactNode;
}> = ({ fallback, loading, children }) => {
  const [authUser, authLoading] = useAuthState(auth);
  const [user, setUser] = useState<AdminUser>();

  /**
   * Function to fetch the data of the currently logged in user and update the context
   */
  const updateUser = useCallback<() => Promise<void>>(async () => {
    if (!authUser) {
      setUser(undefined);
      return;
    }

    try {
      const userSnap = await getDoc(doc(collection(db, "users"), authUser.uid));
      const userPrivInfoSnap = await getDocs(
        collection(db, userSnap.ref.path, "privateInformation")
      );
      const user = userSnap.data();
      const userPrivInfo = userPrivInfoSnap.docs[0]?.data();
      if (!user || !userPrivInfo)
        throw new Error(`User document not found for "${authUser.uid}"`);

      const adminUser: AdminUser = {
        uid: user.uid,
        email: userPrivInfo.email,
        firstName: userPrivInfo.firstName,
        lastName: userPrivInfo.lastName,
      };

      setUser(adminUser);
    } catch (exc) {
      console.error(
        "Error while receiving and setting user data for the currently logged in user",
        exc
      );
    }
  }, [authUser]);

  /**
   * effect to load the data of the currently logged in user.
   * triggers everytime the auth state change.
   */
  useEffect(() => {
    if (authLoading) return;
    updateUser();
  }, [authLoading, updateUser]);

  // user is defined therefore logged in
  if (!!user)
    return (
      <UserContext.Provider value={{ user, update: updateUser }}>
        {children}
      </UserContext.Provider>
    );

  // auth not loading and uauth user undefined therefore logged out
  if (!authLoading && !authUser) return <>{fallback}</>;

  // anywhere else should be in loading state
  return <>{loading}</>;
};

/**
 * Simple shortcut for `useContext(UserContext)`.
 *
 * @returns {@link UserContext}
 */
export const useUser = () => useContext(UserContext);
